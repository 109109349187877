var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"supportRequests",attrs:{"title":"Support Requests","icon":"ticket-alt"}},[_c('template',{slot:"buttons"},[_c('font-awesome-icon',{class:['alt-pointer color-primary mr-2', { 'fa-spin' : _vm.loading}],attrs:{"icon":"sync-alt"},on:{"click":function($event){return _vm.getSupportRequestIssues()}}})],1),_c('div',{staticClass:"supportRequestList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.issues),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.issues),"sortable":{
          allowUnsort: _vm.kgm_allowUnsort,
          mode: _vm.kgm_sortMode
        },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"idLink",fn:function(ref){
        var props = ref.props;
return [_c('td',{staticClass:"alt-primary-color"},[_c('a',{staticClass:"alt-primary-color",attrs:{"target":"_blank","href":_vm.jiraUrl(props.dataItem.jiraIssueKey)}},[_vm._v(" "+_vm._s(props.dataItem.jiraIssueKey)+" ")])])]}},{key:"createdAtCell",fn:function(ref){
        var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}},{key:"textFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"textFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a value"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('textFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"createdFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"createdFilter",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"date"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('createdFilter', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }