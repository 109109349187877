<template>
  <Portlet
    title="Support Requests"
    class="supportRequests"
    icon="ticket-alt"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading}]"
        icon="sync-alt"
        @click="getSupportRequestIssues()"
      />
    </template>
    <div class="supportRequestList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(issues)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(issues)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="idLink"
            slot-scope="{props}"
          >
            <td class="alt-primary-color">
              <a
                target="_blank"
                class="alt-primary-color"
                :href="jiraUrl(props.dataItem.jiraIssueKey)"
              >
                {{ props.dataItem.jiraIssueKey }}
              </a>
            </td>
          </template>
          
          <template
            slot="createdAtCell"
            slot-scope="{props}"
          >
            <td>
              {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
            </td>
          </template>
          <div
            slot="textFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="textFilter"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a value"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('textFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <template
            slot="createdAtCell"
            slot-scope="{props}"
          >
            <td>
              {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
            </td>
          </template>
          <div
            slot="createdFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="createdFilter"
              :value="props.value"
              type="date"
              class="form-control mr-2 rounded-right"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('createdFilter', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
        </Grid>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { mapGetters } from 'vuex';
import { urlParseMixin } from '@/mixins/urlParseMixin';

export default {
  name: "FreshdeskList",
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    urlParseMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      issues: null,
      kgm_columns: [
        {
          field: 'jiraIssueKey',
          filterable: true,
          filter: 'text',
          title: 'Id',
          filterCell: 'textFilter',
          cell: 'idLink',
          width: '150px'
        },
        {
          field: 'summary',
          filterable: true,
          filter: 'text',
          title: this.$t('worklogCustomer.summary'),
          filterCell: 'textFilter',
        },
        {
          field: 'assignee',
          filterable: true,
          filter: 'text',
          title: 'Assignee',
          filterCell: 'textFilter',
          width: '200px',
          hideOn: ['mdDown']
        },
        {
          field: 'status',
          filterable: true,
          filter: 'text',
          title: 'Status',
          filterCell: 'textFilter',
          width: '150px',
          hideOn: ['lgDown']
        },
        {
          field: 'resolution',
          filterable: true,
          filter: 'text',
          title: 'Resolution',
          filterCell: 'textFilter',
          width: '150px',
          hideOn: ['lgDown']
        },
        {
          field: 'created',
          filterable: true,
          filter: 'date',
          title: this.$t('freshdesk.createdAt'),
          filterCell: 'createdFilter',
          cell: 'createdAtCell',
          width: '150px'
        },
      ]
    }
  },
  created () {
    this.getSupportRequestIssues();
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  methods: {
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    //api calls
    getSupportRequestIssues () {
      this.loading = true;
      this.axios.get(`/Issue/GetSupportRequestIssues?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.issues = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.supportRequestList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>